body{
	overflow-y: hidden;
	overflow-x: hidden;
}
.PopUp{
    position: fixed;

    left: 25%;
    top: 30%;

    width: 50%;

    border: 1px solid #011627;
    border-radius: 1em;
    box-shadow: -2px 5px 5px #E71D36;

    background-color: #FDFFFC;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    color: #011627;
}
@media only screen and (min-width: 768px) {
  .palm{
	width: 14rem;
	position:relative;
	z-index:999
}
} 

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .palm{
	width: 35rem;
	position:relative;
	z-index:999
}
}
@media only screen and (max-width: 600px) {
	.palm{
	width: 8rem;
	position:relative;
	z-index:999
}
}
/* .blink_me {
	animation: blinker 1s linear infinite;
  animation-iteration-count: 2;
  } */
  
  @keyframes blinker {
	50% {
	  opacity: 0;
	}
  }
 